//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    islight() {
      return this.$store.state.themelight;
    },
  },
};
